<template>
    <div class="list-box">
        <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>文档</el-breadcrumb-item>
            <el-breadcrumb-item>文档管理</el-breadcrumb-item>
        </el-breadcrumb>
         <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item>
                     <el-input size="normal" @input="search" placeholder="请输入关键字" v-model="form.keys">
                        <!-- <template slot="prepend">关键词</template> -->
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="form.nid" placeholder="选择专栏">
                        <el-option
                        v-for="item in docList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="search" icon="el-icon-search" size="small">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- content -->
        <div class="pro-con">
            <!-- table -->
            <el-table
            border
            v-loading="loading"
            :data="list"
            style="width: 100%">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    prop="title"
                    label="标题">
                    <template slot-scope="scope">
                        <a :href="url+scope.row.nid+'/'+scope.row.id" target="_blank">
                            {{scope.row.title}}
                        </a>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="author"
                    label="作者">
                    <template slot-scope="scope">
                        {{scope.row.author|name("未知")}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="nid"
                    label="专栏">
                    <template slot-scope="scope">
                        {{scope.row.nid|getDocName(docList)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="cid"
                    label="章">
                    <template slot-scope="scope">
                        {{scope.row.nid|getChapter(chapters)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="sid"
                    label="节">
                    <template slot-scope="scope">
                        {{scope.row.cid|getSection(sections)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="time"
                    label="发布时间">
                    <template slot-scope="scope">
                        {{scope.row.time|dateFormat}}
                    </template>
                </el-table-column>
                <el-table-column
                width="100"
                fixed="right"
                label="操作">
                    <template slot-scope="scope">
                        <i class="edit el-icon-edit" @click="handleEdit(scope.row)"></i>
                        <i class="edit el-icon-delete-solid" @click="handleDelete(scope.row.id)"></i>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            url:"",
            loading:false,
            form:{},
            docList:[],
            chapters:[],
            sections:[],
            list:[],
            page:1,
            pageSize:10
        }
    }
    ,
    created(){
        this.url = "/Document/Document/"
        //获取文档
        this.getNameList()
        this.getDocList()
    }
    ,methods:{
        //获取专栏名称列表
        getNameList(){
            let params = {
                method:"getDocNameList"
            }
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                console.log(res)
                let nameList = [],chapterList = [],sections=[]
                res.data.doc_name.forEach(function(item){
                    nameList.push({
                        value:item.id,
                        label:item.title
                    })
                })
                res.data.doc_chapter.forEach(function(item){
                    chapterList.push({
                        value:item.id,
                        label:item.title,
                        nid:item.nid
                    })
                })
                res.data.doc_section.forEach(function(item){
                    sections.push({
                        value:item.id,
                        label:item.title,
                        cid:item.cid
                    })
                })
                this.docList = nameList 
                this.chapters = chapterList 
                this.sections = sections
            }).catch(err=>{
                //err
                this.loading = false
                this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
            })
        },
        handleDelete(id){
            //console.log(id)
            this.$confirm(this.CONST.DEL_CONFIRM, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    let params = {
                        method:"delDoc",
                        id:id
                    }
                    this.loading = true
                    this.$ajax.post(this.API.api,params).then(res=>{
                        //console.log(res)
                        this.loading = false
                        this.totalPage = 0
                        this.page = 1 
                        this.list = []
                        this.getDocList()
                        this.$message({
                            type: 'success',
                            message: res.msg!=''?res.msg:this.CONST.DEL_SUC
                        });
                    }).catch(err=>{
                        this.loading = false
                        this.$message({
                            type: 'warning',
                            message: err.msg?err.msg:err
                        }); 
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.CONST.DEL_CANCEL
                    });          
                });
        },
        handleEdit(data){
            //console.log(id)
            this.$router.push({
                path:"/Document/Editor",
                query:{
                    id:data.id,
                    nid:data.nid,
                    cid:data.cid,
                    sid:data.sid
                }
            })
        },
        search(){
            this.page = 1
            this.totalPage = 0 
            this.getDocList()
        },
        //获取列表
        getDocList(){
            // let params = {
            //     method:"getDocList",
            //     page:this.page,
            //     pageSize:this.pageSize
            // }
            let params = this.form 
            params.method = "getDocList"
            params.page = this.page 
            params.pageSize = this.pageSize
            this.loading = true 
            console.log(params)
            this.$ajax.post(this.API.api,params).then(res=>{
                //res
                console.log(res)
                this.loading = false
                this.list = res.data.list 
                this.totalPage = res.data.totalPage
            }).catch(err=>{
                //err
                console.log(err)
                this.loading = false
                this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
            })
        }
    }
}
</script>
<style lang="less">
    .list-box{
        .header-bar{
            margin-top:10px
        }
        .edit{
            cursor: pointer;
            margin-right:10px
        }
        a{
            color: gray;
        }
    }
</style>